import { createAction, props } from '@ngrx/store';
import { Wishlist } from '../models/wishlist.model';

export const setWishlist = createAction(
    '[Wishlist] Set Wishlist',
    props<{ wishList: Wishlist }>()
);
export const addSite = createAction(
    '[Wishlist] Add Site',
    props<{ site_id: string }>()
);
export const removeSite = createAction(
    '[Wishlist] Remove Site',
    props<{ site_id: string }>()
);
export const addTrek = createAction(
    '[Wishlist] Add Trek',
    props<{ trek_id: string }>()
);
export const removeTrek = createAction(
    '[Wishlist] Remove Trek',
    props<{ trek_id: string }>()
);
export const addItinerary = createAction(
    '[Wishlist] Add Itinerary',
    props<{ itinerary_id: string }>()
);
export const removeItinerary = createAction(
    '[Wishlist] Remove Itinerary',
    props<{ itinerary_id: string }>()
);
export const deleteItinerary = createAction(
    '[Wishlist] delete Itinerary',
    props<{ itinerary_id: string }>()
);
